import { DqCard, DqCardContent } from "@decentriq/components";
import { Grid, Typography } from "@mui/joy";
import { memo } from "react";
import { useDataRoomDrawerContext } from "components/entities/dataRooms/DataRoomDrawer/contexts";
import { DataRoomTypeNames } from "models";

const DataRoomMediaDcrFeatures = memo(() => {
  const { dataRoomOverviewData, __typename, showDcrFeatures } =
    useDataRoomDrawerContext();
  const enableExtendedLookalikeQualityStatistics =
    dataRoomOverviewData &&
    "enableExtendedLookalikeQualityStatistics" in dataRoomOverviewData
      ? dataRoomOverviewData.enableExtendedLookalikeQualityStatistics
      : false;

  if (!showDcrFeatures) {
    return null;
  }

  return (
    <DqCard>
      <DqCardContent sx={{ alignItems: "flex-start" }}>
        <Typography gutterBottom={true} level="body-sm" textColor="inherit">
          <strong>Features</strong>
        </Typography>
        {__typename === DataRoomTypeNames.PublishedMediaInsightsDcr
          ? enableExtendedLookalikeQualityStatistics !== undefined && (
              <Grid container={true} xs={12}>
                <Grid xs={6}>
                  <Typography level="body-sm">
                    <strong>Extended lookalike quality statistics:</strong>
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography level="body-sm">
                    {enableExtendedLookalikeQualityStatistics ? "yes" : "no"}
                  </Typography>
                </Grid>
              </Grid>
            )
          : "No features are visible for this data room yet"}
      </DqCardContent>
    </DqCard>
  );
});

DataRoomMediaDcrFeatures.displayName = "DataRoomMediaDcrFeatures";

export default DataRoomMediaDcrFeatures;
